<template>
  <div class="viewDashBoard">
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-client-vue
          v-if="
              getActiveUserData.userRoles == 'admin' ||
              getActiveUserData.userRoles == 'subadmin' ||
              getActiveUserData.userRoles == 'salesmanager' ||
              getActiveUserData.userRoles == 'salesteamlead' ||
              getActiveUserData.userRoles == 'salesperson'
          "
        ></adminvue-client-vue>
        <no-component-found v-else></no-component-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./showclient/admin-client-show";
import noComponentFound from "../../components/noComponentFound";
export default {
  components: {
    "adminvue-client-vue": adminvue,
    "no-component-found": noComponentFound
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
