<template>
    <section class="comments-area">
        <div class=" invoices-tab">
            <h4 class="sub-heading">Activity:</h4>

            <div class="activity">
                <div class="table-responsive">
                    <table class="table">
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>Message</th>
                        <th>User Meta</th>
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody
                        v-if="
                        typeof user_activities_data.data !==
                            'undefined' &&
                            Object.keys(user_activities_data.data).length
                        "
                    >
                        <tr
                        v-for="(activity, act_index) in user_activities_data.data"
                        :key="act_index"
                        >
                        <td>
                            {{ activity.id }}
                        </td>
                        <td>
                            <p class="">
                                {{ activity.message }}
                            </p>
                        </td>
                        <td>
                            <p class="">
                                {{ activity.user_meta.user_meta[0].Ip }}
                            </p>
                        </td>
                        <td>
                            <p class="">
                                {{ activity.created_at }}
                            </p>
                        </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                        <td colspan="6">
                            No Data Found.
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
// import Avatar from "vue-avatar";
export default {
  components: {
    // Avatar
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      client_comments : {},
      user_activities_data : {}
    };
  },
  mounted() {
    this.getClientActivitiesData();
  },
  methods: {
    async getClientActivitiesData(page = 1, action = null) {
      const $this = this;
      $this.client_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url: "clients/get-activities/" + this.$route.params.client_id +"?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.user_activities_data = response.data;
          console.log(response);
        }
        // $this.client_loader = false;
      });
    },
  }
};
</script>
<style lang="scss" scoped>

</style>
