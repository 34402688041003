<template>
    <section class="comments-area">
        <div class=" invoices-tab">
            <h4 class="sub-heading">Comments:</h4>

            <div class="comments"
            v-if="
                typeof client_comments.data !== 'undefined' &&
                Object.keys(client_comments.data).length
            ">
                <ul class="list-group list-group-flush">
                    <li
                    class="list-group-item"
                    v-for="(comment, comment_index) in client_comments.data"
                    :key="comment_index"
                    :class="[ (comment.current_user == comment.user.id) ? 'text-left icon-left' : 'text-right']"
                    >
                    <div
                        class="media">
                        <avatar
                        class="mr-3"
                        :username="comment.user.name"
                        ></avatar>
                        <div class="media-body">
                        <p>{{ comment.comment }}</p>
                        <p>{{ comment.created_at }}</p>
                        </div>
                    </div>
                    </li>
                </ul>
            </div>


            <form class="comment-form" v-on:submit.prevent="onSubmit($event)">
                <div class="form-group">
                    <input name="comment_input" type="text" class="form-control" placeholder="Enter a comment here">
                </div>
            </form>
            
        </div>
    </section>
</template>

<script>
import Avatar from "vue-avatar";
export default {
  components: {
    Avatar
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      client_comments : {},
    };
  },
  mounted() {
    this.getUserComments();
  },
  methods: {
    async getUserComments() {
      const $this = this;
      $this.client_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url: "clients-comments/"+ this.$route.params.client_id+ "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.client_comments = response.data;
        }
      });
    },
    async onSubmit(event) {
      this.$store.dispatch("generalLoader", {
        response: true
      });
      let comment = event.target.elements.comment_input.value;
      // let client_id = this.client_comments.data.id;
      if((comment == 'null') || (comment == '')) {
        this.$store.dispatch("generalLoader", {
        response: false
        });
        return;
      }
      const $this = this;
      
      const data = $this.$store.dispatch("postHttp", {
        url: "clients-comments",
        formDetails: {
          'client_id' : this.$route.params.client_id,
          'comment' : comment,
        },
        formEvent: null
      });
      await data.then( (response) => {
        if (typeof response !== "undefined") {
          this.$store.dispatch("generalLoader", {
            response: false
          });
          this.getUserComments();
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: 'Comment Posted Successfully!.',
            type: 'success'
          });
          event.target.elements.comment_input.value = '';
          // this.getILeadActivityData();
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.comments {
    ul {
        li {
        position : relative;
        text-align: right;
        padding: 15px 50px 30px;
        p {
            font-family: "Noto Sans TC", sans-serif;
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 10px;
            &:last-child {
            margin-bottom: 10px;
            font-weight: bold;
            }
        }
        .vue-avatar--wrapper.mr-3 {
            position: absolute;
            right: 10px;
            left: unset;
            margin-right: 0 !important;
            top : 12px;
        }
        &.icon-left {
            .vue-avatar--wrapper.mr-3 {
            position: absolute;
            right: unset;
            left: 10px;
            margin-right: 0 !important;
            top : 12px;
        }
        }
        }
    }
    }
</style>
