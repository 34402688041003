var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard clients-route-show"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"white-row"},[_c('div',{staticClass:"custom-charts"}),(
          typeof _vm.client_detail.data !== 'undefined' &&
            Object.keys(_vm.client_detail.data).length
        )?_c('div',{staticClass:"show-client-portal-details"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(_vm._s(_vm.client_detail.data.name)+" "),_c('span',{staticStyle:{"float":"right","font-size":"12px"}},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                  path: '/clients/edit/' + _vm.client_detail.data.id
                                },"tag":"a"}},[_vm._v(" Edit ")])],1)]),_c('section',{staticClass:"section-client-details"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"full-name-client"},[_c('h4',[_vm._v("Full Name:")]),_c('p',[_vm._v(_vm._s(_vm.client_detail.data.name))])])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"client-contact-details"},[_c('h4',[_vm._v("Mobile:")]),_c('p',[_vm._v(_vm._s(_vm.client_detail.data.mobile))])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"client-contact-details"},[_c('h4',[_vm._v("Email:")]),_c('p',[_vm._v(_vm._s(_vm.client_detail.data.email)+" ")])])]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"client-contact-details"},[_c('h4',[_vm._v("Status:")]),_c('p',[_c('Select2',{attrs:{"options":_vm.status_options},on:{"change":function($event){return _vm.updateStatus()}},model:{value:(_vm.clientStatus),callback:function ($$v) {_vm.clientStatus=$$v},expression:"clientStatus"}})],1)])])])]),_c('section',{staticClass:"section-client-account-manager-details"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-8"},[_c('div',{staticClass:"client-contact-details text-right"},[(
                    typeof _vm.client_detail.data.account_manager !==
                      'undefined' &&
                      Object.keys(_vm.client_detail.data.account_manager).length
                  )?_c('ul',{staticClass:"list-inline"},_vm._l((_vm.client_detail.data
                      .account_manager),function(am,am_index){return _c('li',{key:am_index,staticClass:"list-inline-item"},[_c('div',{staticClass:"am-box-wrapper"},[_vm._m(1,true),_c('div',[_c('p',[_vm._v(_vm._s(am.name))])])])])}),0):_vm._e()])])])]),_c('section',{staticClass:"client-projects"},[_c('h4',{staticClass:"sub-heading"},[_vm._v("Projects")]),(
              typeof _vm.client_detail.data.client_projects !== 'undefined' &&
                Object.keys(_vm.client_detail.data.client_projects).length
            )?_c('div',{staticClass:"row"},_vm._l((_vm.client_detail.data
                .client_projects),function(project_data,project_index){return _c('div',{key:project_index,staticClass:"col-sm-3"},[_c('div',{staticClass:"wrap-box"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"project-details"},[_c('p',{staticClass:"project-id"},[_vm._v("ID: "+_vm._s(project_data.id))]),_c('div',{staticClass:"project-listing"},[_c('router-link',{staticClass:"eclipse-para global-para-link2",attrs:{"to":{
                          path: '/projects/show/' + project_data.id
                        },"tag":"a"}},[_vm._v(" "+_vm._s(project_data.project_name)+" ")])],1),_c('p',{staticClass:"project-status"},[_vm._v(" Status: "+_vm._s(project_data.project_status.status_name)+" ")])]),_c('div',{staticClass:"footer-box"},[_c('div',{staticClass:"relv"},[_vm._m(2,true),(project_data.project_account_manager.length)?_c('div',{staticClass:"usr-role-name-glb text-right"},_vm._l((project_data.project_account_manager),function(am,am_index){return _c('div',{key:am_index,class:_vm.avatarClass(
                              project_data.project_account_manager,
                              am_index
                            )},[_c('a',{attrs:{"title":am.name}},[_c('avatar',{attrs:{"inline":true,"username":am.name}})],1),_vm._v(" "+_vm._s(project_data.project_account_manager_total_count > 3 && am_index + 1 > 3 ? "+" + project_data.project_account_manager_remaining_count : "")+" ")])}),0):_c('div',{staticClass:"usr-role-name-glb text-right right-pd-zero"},[_vm._v(" N/A ")])])])])])])}),0):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_vm._v(" No project assign yet! ")])])]),_c('section',{staticClass:"invoices-area"},[_c('div',{staticClass:" invoices-tab"},[_c('h4',{staticClass:"sub-heading"},[_vm._v("Invoices:")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(3),(
                    typeof _vm.client_detail.data.client_invoice !==
                      'undefined' &&
                      Object.keys(_vm.client_detail.data.client_invoice).length
                  )?_c('tbody',_vm._l((_vm.client_detail.data
                      .client_invoice),function(invoice,inv_index){return _c('tr',{key:inv_index},[_c('td',[_c('router-link',{staticClass:"invoice-title global-para-link2",attrs:{"to":{ path: '/invoices/show/' + invoice.id },"tag":"a"}},[_vm._v(" "+_vm._s(invoice.invoice_number)+" ")])],1),_c('td',[_c('p',{staticClass:"invoice-total bold-cls"},[_vm._v(" $ "+_vm._s(_vm._f("numFormat")(invoice.total))+" ")])]),_c('td',[_c('p',{class:[
                          invoice.status == 'paid'
                            ? 'invoice-status success'
                            : 'invoice-status danger'
                        ]},[_vm._v(" "+_vm._s(invoice.status)+" ")])]),_c('td',[_c('p',{},[_vm._v(" "+_vm._s(invoice.issue_date)+" ")])]),_c('td',[_c('p',{},[_vm._v(" "+_vm._s(invoice.due_date)+" ")])]),_vm._m(4,true)])}),0):_c('tbody',[_vm._m(5)])])])])]),_c('section',{staticClass:"invoices-area"},[_c('div',{staticClass:" invoices-tab"},[_c('h4',{staticClass:"sub-heading"},[_vm._v("Old Payments:")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(6),(
                    typeof _vm.old_payments_data.data !==
                      'undefined' &&
                      Object.keys(_vm.old_payments_data.data).length
                  )?_c('tbody',_vm._l((_vm.old_payments_data.data),function(old_invoice,old_inv_index){return _c('tr',{key:old_inv_index},[_c('td',[_vm._v(" "+_vm._s(old_invoice.id)+" ")]),_c('td',[_c('p',{staticClass:"invoice-total bold-cls"},[_vm._v(" "+_vm._s(old_invoice.order_id)+" ")])]),_c('td',[_c('p',[_vm._v(" "+_vm._s(old_invoice.order_date)+" ")])]),_c('td',[_c('p',{},[_vm._v(" "+_vm._s(old_invoice.project_name)+" ")])]),_c('td',[_c('p',{},[_vm._v(" "+_vm._s(old_invoice.product)+" ")])]),_c('td',[_c('p',{},[_vm._v(" $ "+_vm._s(old_invoice.amount)+" ")])])])}),0):_c('tbody',[_vm._m(7)])])])])]),_c('comment-component'),_c('client-scheduler'),(
            _vm.getActiveUserData.userRoles == 'admin'
          )?_c('client-activity'):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"full-name-of-am-client"},[_c('p',{staticClass:"sub-heading"},[_vm._v("Account Managers:")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":"/images/user-profile-icon.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"usr-role"},[_c('p',[_vm._v("Account Managers:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Invoice Number")]),_c('th',[_vm._v("Total")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Issue Date")]),_c('th',[_vm._v("Due Date")]),_c('th',{staticClass:"text-right"},[_vm._v("Download")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"download-box text-right"},[_c('a',{staticClass:"invoice-icon",attrs:{"data-toggle":"tooltip","title":"Download!","data-placement":"top","href":"#"}},[_c('p',{staticClass:"invoice-icon"},[_c('i',{staticClass:"fas fa-download"})])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v(" No Data Found. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Order ID")]),_c('th',[_vm._v("Order Date")]),_c('th',[_vm._v("Project Name")]),_c('th',[_vm._v("Product Name")]),_c('th',[_vm._v("Amount")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"colspan":"6"}},[_vm._v(" No Data Found. ")])])}]

export { render, staticRenderFns }